import { login, logout, getInfo, getjurisdiction } from "@/api/user";
import { getToken, setToken, removeToken, setUserId } from "@/utils/auth";
import { resetRouter } from "@/router";
import { MessageBox, Message } from "element-ui";
const getDefaultState = () => {
	return {
		token: getToken(),
		name: "",
		avatar: "",
		roles: [],
		levelList: [
			"养树新人",
			"养树达人",
			"养树导师",
			"养树专家",
			"合伙人",
			"分公司",
		],
		user: {},
	};
};

const state = getDefaultState();

const mutations = {
	RESET_STATE: (state) => {
		Object.assign(state, getDefaultState());
	},
	SET_TOKEN: (state, token) => {
		state.token = token;
	},
	SET_NAME: (state, truename) => {
		state.name = truename;
	},
	SET_AVATAR: (state, avatar_img) => {
		state.avatar = avatar_img;
	},
	SET_ROLES: (state, roles) => {
		state.roles = roles;
	},
	SET_User: (state, user) => {
		state.user = user;
	},
};

const actions = {
	// user login
	login({ commit }, userInfo) {
		const { username, password } = userInfo;
		return new Promise((resolve, reject) => {
			login({ account: username.trim(), password: password })
				.then((response) => {
					if (response.code != 200) {
						Message({
							message: response.data.message,
							type: "error",
							duration: 3 * 1000,
						});
						return;
					}

					commit("SET_TOKEN", response.data.token);
					setToken(response.data.token);
					setUserId(response.data.userinfo.id);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	// get user info
	getInfo({ commit, state }) {
		return new Promise((resolve, reject) => {
			getInfo(state.token)
				.then((response) => {
					if (response.code == 1005) {
						Message({
							message: response.message || "Error",
							type: "error",
							duration: 5 * 1000,
						});
						return;
					}
					const { data } = response;
					if (!data) {
						return reject("验证失败，请重新登录");
					}
					const { truename, avatar_img } = data;
					commit("SET_NAME", truename);
					commit("SET_AVATAR", avatar_img);
					commit("SET_User", data);
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	// user logout
	logout({ commit, state }) {
		return new Promise((resolve, reject) => {
			logout(state.token)
				.then(() => {
					removeToken(); // must remove  token  first
					// resetRouter()

					commit("RESET_STATE");

					location.reload();
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	// remove token
	resetToken({ commit }) {
		return new Promise((resolve) => {
			removeToken(); // must remove  token  first
			commit("RESET_STATE");
			resolve();
		});
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
