import Cookies from "js-cookie";

const TokenKey = "wwy-treeadmin-users";
const TokenIDKey = "wwy-treeadmin-usersid";
// const userKey = 'wwy-vecoadmin-users'

export function getToken() {
	return Cookies.get(TokenKey);
}

export function setToken(token) {
	return Cookies.set(TokenKey, token);
}

export function removeToken() {
	return Cookies.remove(TokenKey);
}

export function getUserToken() {
	return Cookies.get(TokenKey);
}

export function setUserId(userId) {
	return Cookies.set(TokenIDKey, userId);
}
export function getUserId() {
	return Cookies.get(TokenIDKey);
}
